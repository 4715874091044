<template>
	<div>
		<b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Other Details'" variant="success"
			@click.stop="row.toggleDetails" class="mr-1 mt-1">
			<i class="fa fa-eye-slash" v-if="row.detailsShowing"></i>
			<i class="fa fa-eye" v-else></i>
		</b-button>

		<b-button v-show="(row.item.status === 'Draft' || row.item.status === 'On-Going') && !isViewer" size="sm"
			v-b-modal.edit-inventory-session v-b-tooltip.hover.top="'Edit Details'" variant="dark"
			@click.stop="updateSelInventorySession(row.item, row.index, $event.target)" class="mr-1 mt-1">
			<i class="fa fa-pencil"></i>
		</b-button>

		<b-button v-show="(row.item.status == 'Draft' || row.item.status == 'On-Going') && !isViewer" size="sm"
			v-b-modal.cancel-inventory-session v-b-tooltip.hover.top="'Cancel Inventory Session'" variant="danger"
			@click.stop="updateSelInventorySession(row.item, row.index, $event.target)" class="mr-1 mt-1">
			<i class="fa fa-ban"></i>
		</b-button>

		<b-button v-show="row.item.status == 'On-Going' && !isViewer" size="sm" variant="primary"
			v-b-modal.mark-as-done-inventory-session v-b-tooltip.hover.top="'Mark Inventory Session as Done'"
			class="mr-1 mt-1" @click.stop="updateSelInventorySession(row.item, row.index, $event.target)">
			<i class="fa fa-check"></i>
		</b-button>
	</div>
</template>

<script>
import EventBus from '@/shared/event-bus';

export default {
	name: 'inventory-row-actions',
	props: {
		row: {
			type: Object,
			required: true
		},
		isSuperAdmin: {
			type: Boolean,
			required: true
		},
		isViewer: {
			type: Boolean,
			required: true
		},
		params: {
			type: Object,
			required: true
		}
	},
	methods: {
		updateSelInventorySession(item, _index, _button) {
			this.$store.commit('SET_CURR_INVENTORY_SESSION', item);
			EventBus.$emit('onUpdateSelInventorySession', item);
		}
	}
};
</script>

