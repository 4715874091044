import { db } from '@/config/firebase';

function generateQuery(filter) {
    let query = db.collection("inventorySessions");

    if (filter.sessionId && filter.sessionId.length > 0) {
        query = query.where("sessionId", "==", filter.sessionId);
    } else {
        if (filter.fromTimestamp && filter.fromTimestamp > 0) {
            query = query.where("dateCreated", ">=", filter.fromTimestamp);
        }

        if (filter.toTimestamp && filter.toTimestamp > 0) {
            query = query.where("dateCreated", "<=", filter.toTimestamp);
        }

        if (filter.company && filter.company.id && filter.company.id.length > 0) {
            query = query.where("companyId", "==", filter.company.id);
        }

        if (filter.status && filter.status.length > 0) {
            query = query.where("status", "==", filter.status);
        }

        if (filter.user && filter.user.id && filter.user.id.length > 0) {
            query = query.where("allowedScanners", "array-contains", filter.user.id);
        } else if (filter.assetCode && filter.assetCode.length > 0) {
            query = query.where("scannedAssets", "array-contains", filter.assetCode);
        }
    }
    
    return query;
}

function getInventorySessionListener(filter, callback) {
    let query = generateQuery(filter);
    let listener = query.onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
            callback(change.type, change.doc.data());
        });
    });
    return listener;
}

export default {
    getInventorySessionListener
}