<template>
	<b-modal
		id="cancel-inventory-session"
		title="Cancel Inventory Session"
		ref="modal"
		ok-title="Save"
		@ok="handleOk"
		@show="onReset"
		:cancel-disabled="disableConfirmButtons"
		:ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true"
	>
		<loading
			:active.sync="isLoading"
			loader="spinner"
			color="#20A8D8"
			:is-full-page="false"
		/>

		<div class="confirmMessage">
			Are you sure you want to
			<b>cancel</b> this session <b>{{ selInventorySession.sessionId }}</b
			>?
		</div>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';

// API
import inventorySessionApi from '@/api/inventorySessionApi';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	name: 'cancel-inventory-session',
	components: {
		Loading,
	},
	data() {
		return {
			selInventorySession: {},

			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,

			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
	},
	mounted() {
		EventBus.$on('onUpdateSelInventorySession', (param) => {
			this.selInventorySession = param;
		});
	},
	methods: {
		handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			this.selInventorySession['status'] = 'Cancelled';
			this.selInventorySession['dateCancelled'] =
				DateUtil.getCurrentTimestamp();
			this.selInventorySession['cancelledBy'] = this.loggedUser.id;

			this.cancelInventorySession();
		},
		async cancelInventorySession() {
			let sessionId = this.selInventorySession.sessionId;
			try {
				let { data } = await inventorySessionApi.cancelInventorySession(
					this.selInventorySession,
					this.loggedUser.id,
					DateUtil.getCurrentTimestamp()
				);

				// hide loading indicator
				this.isLoading = false;
				this.$refs.modal.hide();

				if (data.isSuccess) {
					this.$toaster.success(data.message);
					EventBus.$emit(
						'onCloseCancelInventorySession',
						data.inventorySession
					);
				} else {
					this.$toaster.error(
						`Error cancelling Inventory Session "${sessionId}". Please try again.`
					);
					EventBus.$emit('onCloseCancelInventorySession', {});
				}
			} catch (error) {
				this.$toaster.error(
					`Error cancelling Inventory Session "${sessionId}". Please try again.`
				);

				// hide loading indicator
				this.isLoading = false;
			}
		},
		onReset() {
			// do nothing
		},
	},
	beforeDestroy() {
		EventBus.$off('onUpdateSelInventorySession');
	},
};
</script>

<style scoped>
</style>
