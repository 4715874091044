<template>
	<b-modal
		id="add-inventory-session"
		title="Add Inventory Session"
		size="lg"
		ref="modal"
		ok-title="Add"
		@ok="handleOk"
		@show="onReset"
		:cancel-disabled="disableConfirmButtons"
		:ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true"
	>
		<loading
			:active.sync="isLoading"
			loader="spinner"
			color="#20A8D8"
			:is-full-page="false"
		/>

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<b-row class="my-2">
					<b-col sm="8">
						<b>SESSION INFORMATION</b>
					</b-col>
				</b-row>
				<b-row class="my-12">
					<b-col lg="6" md="12" sm="12">
						<b-form-group label="Company">
							<v-select
								name="Company"
								class="style-chooser"
								label="text"
								:options="companyOptions"
								:reduce="(company) => company.value"
								v-model="selCompany"
								v-validate="'selectRequired'"
							>
								<template v-slot:no-options="{ search, searching }">
									<template v-if="searching">
										No results found for
										<em>
											<strong>{{ search }}</strong>
										</em>
									</template>
									<em :style="{ opacity: 0.5 }" v-else>
										Start typing to search for a company
									</em>
								</template>
							</v-select>
							<span v-show="errors.has('Company')" class="help-block">{{
								errors.first('Company')
							}}</span>
						</b-form-group>
					</b-col>

					<b-col lg="6" md="12" sm="12">
						<b-form-group label="Storage Location">
							<v-select
								name="Storage Location"
								class="style-chooser"
								label="text"
								:options="storageLocationOptions"
								:reduce="(loc) => loc.value"
								v-model="selStorageLocation"
								v-validate="'selectRequired'"
							>
								<template v-slot:no-options="{ search, searching }">
									<template v-if="searching">
										No results found for
										<em>
											<strong>{{ search }}</strong>
										</em>
									</template>
									<em :style="{ opacity: 0.5 }" v-else>
										Start typing to search for a storage location
									</em>
								</template>
							</v-select>
							<span
								v-show="errors.has('Storage Location')"
								class="help-block"
								>{{ errors.first('Storage Location') }}</span
							>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row class="my-12">
					<b-col lg="6" md="6" sm="12">
						<b-form-group
							label="Description:"
							label-for="description"
							description
						>
							<b-form-textarea
								name="Description"
								type="text"
								v-model="form.description"
								maxlength="200"
								v-validate="'required'"
								:rows="3"
								placeholder="Description"
							/>
							<span v-show="errors.has('Description')" class="help-block">{{
								errors.first('Description')
							}}</span>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row class="my-2">
					<b-col sm="8">
						<b>LIST OF SCANNERS</b>
					</b-col>
				</b-row>
				<b-row no-gutters class="my-12">
					<b-col sm="6" class="mr-2">
						<b-form-group
							label="Select Scanners"
							label-for="scanners"
							description="The person you want to include to scan in this inventory session"
						>
							<v-select
								name="Scanner"
								class="style-chooser"
								label="text"
								:options="userOptions"
								:reduce="(user) => user.value"
								v-model="selScanner"
							>
								<template v-slot:no-options="{ search, searching }">
									<template v-if="searching">
										No results found for
										<em>
											<strong>{{ search }}</strong>
										</em>
									</template>
									<em :style="{ opacity: 0.5 }" v-else>
										Start typing to search for a user
									</em>
								</template>
							</v-select>
						</b-form-group>
					</b-col>
					<b-col lg="3" md="4" sm="4">
						<b-button variant="primary" class="addButton" @click="addScanner">
							Add Scanner
						</b-button>
					</b-col>
				</b-row>

				<div>
					<b-row class="mt-4 mb-2">
						<b-col sm="4" class="md-left-text-sm-right">
							<b>SELECTED SCANNERS</b>
						</b-col>
					</b-row>

					<b-row class="mb-2" v-show="form.scanners.length > 0">
						<b-col sm="3" class="text-sm-left">
							<b>Email</b>
						</b-col>
						<b-col sm="3" class="text-sm-left">
							<b>Name</b>
						</b-col>
						<b-col sm="4" class="text-sm-left">
							<b>Location</b>
						</b-col>
						<b-col sm="1" class="text-sm-left">
							<b>Action</b>
						</b-col>
					</b-row>
					<b-row
						class="mb-2"
						v-bind:key="scanner.id"
						v-for="scanner in form.scanners"
					>
						<b-col sm="3" class="text-sm-left">
							{{ scanner.id }}
						</b-col>
						<b-col sm="3" class="text-sm-left">
							{{ scanner.name }}
						</b-col>
						<b-col sm="4" class="text-sm-left">
							<b-form-group>
								<b-form-input
									name="location"
									v-model="selScanners[scanner.id]"
									placeholder="Enter scanner location"
									v-validate="'required'"
								/>
								<span v-show="errors.has('location')" class="help-block">{{
									errors.first('location')
								}}</span>
							</b-form-group>
						</b-col>
						<b-col sm="1" class="text-sm-left">
							<b-button
								size="sm"
								v-b-tooltip.hover.top="'Remove Scanner'"
								variant="secondary"
								@click.stop="removeScanner(scanner.id)"
								class="mr-1"
							>
								<i class="fa fa-trash"></i>
							</b-button>
						</b-col>
					</b-row>

					<b-row class="mb-2" v-show="form.scanners.length === 0">
						<b-col sm="6" class="text-sm-left">
							<i>There is no selected scanners yet.</i>
						</b-col>
					</b-row>
				</div>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { ValidationUtil } from '@/utils/validationUtil';

// API
import inventorySessionApi from '@/api/inventorySessionApi';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	name: 'add-inventory-session',
	components: {
		Loading,
	},
	props: {
		allCompanyOptions: {
			type: Array,
			required: true,
		},
		allStorageLocationOptions: {
			type: Array,
			required: true,
		},
		allUserOptions: {
			type: Array,
			required: true,
		},
		isSuperAdmin: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			form: {
				sessionId: '',
				status: '',
				company: '',
				companyId: '',
				storageLocation: '',
				storageLocationId: '',
				description: '',
				scanners: [],
				allowedScanners: [],
				scannedAssets: [],
				inputAssetLog: config.inputAssetLogDefaultValue,
				dateCreated: null,
				createdBy: '',
				dateUpdated: null,
				updatedBy: '',
				dateStarted: null,
				startedBy: '',
				dateCancelled: null,
				cancelledBy: '',
			},

			companyOptions: [],
			storageLocationOptions: [],
			userOptions: [],

			selCompany: config.companyDefaultValue,
			selStorageLocation: config.storageLocationDefaultValue,
			selScanner: config.userDefaultValue,
			selScanners: {},

			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,

			// Check for loader
			isLoading: false,
		};
	},
	watch: {
		allCompanyOptions: function () {
			this.companyOptions = this.allCompanyOptions;
			this.storageLocationOptions.push({
				value: config.storageLocationDefaultValue,
				text: ' - Please select - ',
			});
			this.userOptions.push({
				value: config.userDefaultValue,
				text: ' - Please select - ',
			});
		},
		selCompany: function () {
			// reset selected scanners
			this.form.scanners = [];

			if (this.selCompany.id) {
				this.retrieveStorageLocation();
				this.retrieveUsers();
			}
		},
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
	},
	methods: {
		retrieveStorageLocation() {
			this.storageLocationOptions = [];
			this.storageLocationOptions.push({
				value: config.storageLocationDefaultValue,
				text: ' - Please select - ',
			});
			this.allStorageLocationOptions.forEach((loc) => {
				if (loc.value.companyId === this.selCompany.id) {
					this.storageLocationOptions.push(loc);
				}
			});

			this.selStorageLocation = config.storageLocationDefaultValue;
		},
		retrieveUsers() {
			this.userOptions = [];

			this.userOptions.push({
				value: config.userDefaultValue,
				text: ' - Please select - ',
			});

			this.allUserOptions.forEach((user) => {
				if (user.value.companyId === this.selCompany.id) {
					this.userOptions.push(user);
				}
			});

			this.selScanner = config.userDefaultValue;
		},
		isExistingScanner(userId) {
			return ValidationUtil.arrayHasValueDirectField(
				this.form.scanners,
				'id',
				userId
			);
		},
		addScanner() {
			if (this.selScanner.id === null) {
				this.$toaster.warning('Please select a scanner to add.');
				return;
			}

			if (this.isExistingScanner(this.selScanner.id)) {
				let scannerName = this.selScanner.name;
				this.$toaster.warning(`Scanner "${scannerName}" already added.`);
				return;
			}

			let scanner = Object.assign({}, this.selScanner);
			scanner['location'] = 'N/A';
			scanner['scannedAssets'] = [];
			scanner['isDone'] = 'false';
			this.form.scanners.push(scanner);
		},
		removeScanner(scannerId) {
			for (var i = 0; i < this.form.scanners.length; i++) {
				let scanner = this.form.scanners[i];
				if (scanner.id === scannerId) {
					this.form.scanners.splice(i, 1);
					delete this.selScanners[scannerId];
				}
			}
		},

		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				return;
			} else if (this.form.scanners.length === 0) {
				this.$toaster.warning('At least 1 selected scanner is required.');
				return;
			} else {
				this.handleSubmit();
			}
		},
		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			this.form.sessionId = 'IS' + DateUtil.getCurrentTimestamp();
			this.updateCompanyAndLocation();
			this.updateScannersAssignedLocation();
			this.form.dateUpdated = DateUtil.getCurrentTimestamp();
			this.form.updatedBy = this.loggedUser.id;

			//verify inventory session if on-going
			let isEmpty = await this.$store.dispatch(
				'verifyOnGoingInventorySession',
				this.form.storageLocationId
			);

			if (isEmpty) {
				this.addInventorySession(this.form);
			} else {
				// hide loading indicator
				this.isLoading = false;
				this.$toaster.warning(
					'Existing on-going inventory session for the storage location.'
				);
			}
		},
		async addInventorySession(inventorySession) {
			try {
				let { data } = await inventorySessionApi.addInventorySession(
					inventorySession,
					this.loggedUser.id,
					DateUtil.getCurrentTimestamp()
				);

				// hide loading indicator
				this.isLoading = false;

				if (data.isSuccess) {
					this.$toaster.success(data.message);
					EventBus.$emit('onCloseAddInventorySession', data.inventorySession);
					this.$refs.modal.hide();
				} else {
					this.$toaster.error(
						`Error creating Inventory Session "${inventorySession.sessionId}". Please try again.`
					);
				}
			} catch (error) {
				this.$toaster.error(
					`Error creating Inventory Session "${inventorySession.sessionId}". Please try again.`
				);

				// hide loading indicator
				this.isLoading = false;
			}
		},
		updateCompanyAndLocation() {
			this.form.company = this.selCompany.name;
			this.form.companyId = this.selCompany.id;
			this.form.storageLocation = this.selStorageLocation.name;
			this.form.storageLocationId = this.selStorageLocation.id;
		},
		updateScannersAssignedLocation() {
			this.form.scanners.forEach((scanner) => {
				let email = scanner.id;
				scanner['location'] = this.selScanners[email];
			});
			this.form.allowedScanners = _.map(this.form.scanners, 'id');
		},

		onReset() {
			/* Reset our form values */
			this.form.sessionId = '';
			this.form.status = '';
			this.form.company = '';
			this.form.companyId = '';
			this.form.storageLocation = '';
			this.form.storageLocationId = '';
			this.form.description = '';
			this.form.scanners = [];
			this.form.allowedScanners = [];
			this.form.scannedAssets = [];
			this.form.inputAssetLog = config.inputAssetLogDefaultValue;
			this.form.dateCreated = null;
			this.form.createdBy = '';
			this.form.dateUpdated = null;
			this.form.updatedBy = '';
			this.form.dateStarted = null;
			this.form.startedBy = '';
			this.form.dateCancelled = null;
			this.form.cancelledBy = '';

			if (!this.isSuperAdmin) {
				// pre-select the current company
				this.selCompany = DropDownItemsUtil.getCompanyItem(
					this.loggedUserCompany
				);
			} else {
				this.selCompany = config.companyDefaultValue;
			}

			this.selStorageLocation = config.storageLocationDefaultValue;
			this.selScanner = config.userDefaultValue;

			// reset validation
			this.$validator.reset();
			this.errors.clear();
		},
	},
};
</script>

<style scoped></style>
